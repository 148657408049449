import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import * as ScanditSDK from 'scandit-sdk';
import { CardContent, Stack } from '@mui/material';
import '../Home.css';
import Button from '../../reusableComponents/Button';
import { environment } from '../../../environments/environment';
import GS1DigitalLinkToolkit from '../../../assets/js/GS1DigitalLinkToolkit';

const CustomH2 = styled.h2`
  font-family: 'JohnsonDisplay-Regular', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
`;
const CustomP = styled.p`
  font-family: 'JohnsonText-Regular', sans-serif;
  font-weight: 400;
  font-size: 16px;
  width: 377px;
  line-height: 24px;
  color: #ffffff;
  margin: auto;
`;
const customButton = {
  backgroundColor: 'white',
  color: 'red',
  borderRadius: '8px',
  gap: '10px',
  height: '40px',
  border: 'none',
  fontFamily: 'JohnsonText-Regular, sans-serif',
  fontWeight: '700',
  fontSize: '13px',
  lineHeight: '16px',
  minWidth: '100px',
  maxWidth: '160px',
};
function Scan({ isMobile }) {
  const [isScannerVisible, setIsScannerVisible] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initializeBarcodePicker = () => {
    if (isScannerVisible) {
      return;
    }
    setIsScannerVisible(true);
    let licenseKey = environment.scanditKey;
    if (window.location.hostname.includes('epi.jnj')) {
      licenseKey = environment.scanditKeyEpi;
    } else if (window.location.hostname === 'elabel.jnj.com') {
      licenseKey = environment.scanditKeyElabel;
    }
    ScanditSDK.configure(licenseKey, {
      engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/',
      loadTextRecognition: true,
    }).then(() => {
      //including all possible symbologies
      const symbology = [
        ScanditSDK.Barcode.Symbology.EAN8,
        ScanditSDK.Barcode.Symbology.EAN13,
        ScanditSDK.Barcode.Symbology.UPCA,
        ScanditSDK.Barcode.Symbology.UPCE,
        ScanditSDK.Barcode.Symbology.CODE128,
        ScanditSDK.Barcode.Symbology.CODE39,
        ScanditSDK.Barcode.Symbology.DATA_MATRIX,
        ScanditSDK.Barcode.Symbology.GS1_DATABAR,
        ScanditSDK.Barcode.Symbology.GS1_DATABAR_EXPANDED,
        ScanditSDK.Barcode.Symbology.GS1_DATABAR_LIMITED,
        ScanditSDK.Barcode.Symbology.INTERLEAVED_2_OF_5,
        ScanditSDK.Barcode.Symbology.QR,
      ];
      const barcodePicker = ScanditSDK.BarcodePicker;
      const scanWindow = document.getElementById('scandit-window');
      // barcodePicker.create(newWindow.document.body, {
      barcodePicker
        .create(scanWindow, {
          playSoundOnScan: true,
          enablePinchToZoom: true,
          enableTapToFocus: true,
          videoFit: ScanditSDK.BarcodePicker.ObjectFit.COVER,
          viewfinderArea: {
            width: 0.8,
            height: 0.6,
            x: 0.1,
            y: 0.2,
          },
          // constraints: {
          //     video: {
          //       width: { min: 640, ideal: 800, max: 800 }, // Adjust as needed
          //       height: { min: 480, ideal: 600, max: 600 }, // Adjust as needed
          //     },
        })
        .then(function (barcodePicker) {
          const scanSettings = new ScanditSDK.ScanSettings({
            enabledSymbologies: symbology,
            codeDuplicateFilter: 1000,
            maxNumberOfCodesPerFrame: 1,
            blurryRecognition: true,
          });

          // set inverse recognition
          symbology.forEach((symbology) => {
            scanSettings.getSymbologySettings(symbology).colorInvertedEnabled = true;
          });

          barcodePicker.applyScanSettings(scanSettings);
          barcodePicker.on('scan', (scanResult) => {
            if (scanResult != undefined) {
              /** get format code */
              const format = scanResult.barcodes[0].symbology;
              /** get symbology name */
              const formatName = ScanditSDK.Barcode.Symbology.toHumanizedName(format);

              if (format == ScanditSDK.Barcode.Symbology.QR) {
                alert(t('main.GTINSearch.errMessage3'));

                return;
              }


              barcodePicker.destroy();
              setIsScannerVisible(false);
              handleScan({
                format: format,
                formatName: formatName,
                rawData: scanResult.barcodes[0].data,
              });
            }
          });
        });
    });
  };
  const handleScan = (data) => {
    let result = { ...data };
    let gs1dlt = new GS1DigitalLinkToolkit();
    if (result.rawData.length == 8 || result.rawData.length == 12 || result.rawData.length == 13) {
      result.rawData = result.rawData.padStart(14, '0');
      result.rawData = '01' + result.rawData;
    }
    let gs1Array;
    if (result.rawData.indexOf('http') == 0) {
      if (result.rawData.includes('linkType=gs1:')) {
        window.open(result.rawData, '_blank');
      } else {
        try {
          gs1Array = gs1dlt.extractFromGS1digitalLink(result.rawData );
        } catch (error) {
          result.validCode = false;
        }
      }
    } else {
      try {
        result.rawData = result.rawData.replace('0', '0');
        gs1Array = gs1dlt.extractFromGS1elementStrings(result.rawData);
      } catch (error) {
        result.validCode = false;
        // alert(error);
        alert(t('main.GTINSearch.errMessage2'));
      }
    }
    if (Object.keys(gs1Array).length == 0) {
      result.validCode = false;
    }
    try {
      result.gs1ElementStrings = '';
      Object.keys(gs1Array).forEach(function (key) {
        result.gs1ElementStrings += '(' + key + ')' + gs1Array[key] + '';
      });
      result.gs1DigitalLinkURI = gs1dlt.gs1ElementStringsToGS1DigitalLink(
        result.gs1ElementStrings,
        false,
        //environment.gs1url,
        window.location.origin,
        //'https://qa.elabel.jnj.com/'
      );
      console.log(result.gs1DigitalLinkURI);
    } catch (e) {
      console.log(e.message);
    }
    // Fetch headers
    let headers = new Headers();
    headers.append('Accept-Language', '');
    headers.append('Accept', 'application/linkset+json');

    fetch('/save_activity_data/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        activity_type: 'action',
        additional_data: {
          action: 'scan',
          gtin: gs1Array['01'] || gs1Array['gtin'],
          gs1DigitalLinkURI: result.gs1DigitalLinkURI,
        },
      }),
    });

    fetch(result.gs1DigitalLinkURI, { headers, method: 'HEAD' })
      .then((response) => {
        const redirect_url = response.headers.get('location');

        if (redirect_url) {
          return {
            url: redirect_url,
            status: 302,
          };
        }
        if (!response.ok) {
          throw new Error(response.statusText);
        }
      })
      .then((data) => {
        if (data?.status == 302) {
          window.open(data.url, '_self');
          return;
        }
        navigate(
          '/search/' + encodeURIComponent(gs1dlt.gs1digitalLinkToGS1elementStrings(result.gs1DigitalLinkURI, true)),
          { state: result },
        );
      })
      .catch((error) => {
        console.log(error);
        // alert('Sorry, we could not find any information related to this product');
        alert(t('main.GTINSearch.errMessage2'));
      });
  };
  return (
    <>
      <Card
        sx={{
          backgroundColor: 'red',
          borderRadius: '16px',
          width: isMobile ? '100%' : '50%',
        }}
      >
        <CardContent>
          <CustomH2>{t('main.scanCard.btnLabel')}</CustomH2>
          <Stack
            direction={'row'}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <CustomP>{t('main.scanCard.content')}</CustomP>
            <Button
              style={customButton}
              name={t('main.scanCard.btnLabel')}
              id="scan-button"
              onClick={initializeBarcodePicker}
            />
          </Stack>
        </CardContent>
        <div id="scandit-window" style={{ display: isScannerVisible ? 'block' : 'none' }}>
          <div className="scandit-close" onClick={() => setIsScannerVisible(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              style={{
                position: 'fixed',
                top: '16px',
                right: '16px',
                zIndex: 999,
              }}
            >
              <rect width="40" height="40" rx="20" fill="#071934" fillOpacity="0.42" />
              <path
                d="M28 13.4L26.6 12L20 18.6L13.4 12L12 13.4L18.6 20L12 26.6L13.4 28L20 21.4L26.6 28L28 26.6L21.4 20L28 13.4Z"
                fill="#FAF9F7"
              />
            </svg>
          </div>
          <div
            className="scandit-viewfinder"
            style={{
              position: 'absolute',
              height: '260px',
              width: '70%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 99,
            }}
          >
            <img src="overlay.png" style={{ width: '100%', height: '100%' }} />
          </div>
        </div>
      </Card>
    </>
  );
}
export default Scan;
